//
// Color system
//
$darkBlue: rgb(13, 103, 151) !default;
$lightBlue: #006699 !default;
$orange: #ed9c1a !default;
$brown: #555555 !default;

$white: #fff !default;
$font-white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-background: #fafafa !default;
$gray-border: #eeeeee;
$black: #000 !default;

$blue: #2c3e50 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #990000 !default;
// $orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #18bc9c !default;
$teal: #20c997 !default;
$cyan: #3498db !default;

$primary: $darkBlue !default;
$secondary: $white !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-700 !default;

// global

$border-width: 1px !default;
$body-bg: $white !default;
$body-color: $gray-900 !default;

// headings

$headings-font-weight: 100 !default;

// Links

$link-color: $success !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Fonts

$font-family-sans-serif: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base: 0.82rem !default;

// $h1-font-size: 3rem !default;
// $h2-font-size: 2.5rem !default;
// $h3-font-size: 2rem !default;
// $h6-font-size: 0.9rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// Tables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.95rem !default;
$table-cell-padding-sm: 0.4rem !default;

$table-bg: $white !default;
// $table-accent-bg: $gray-100 !default;
$table-accent-bg: rgba($lightBlue, 0.025) !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: 0px !default;
$table-border-color: transparent !default;

$table-head-bg: $white !default;
$table-head-color: $darkBlue !default;

// Dropdowns

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-link-padding-y: 0.5rem !default !default;
$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-600 !default !default;

$nav-tabs-border-color: $gray-200 !default;

// Navbar

$navbar-padding-y: 1rem !default;

$navbar-dark-color: $white !default;
$navbar-dark-hover-color: $success !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $success !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: darken($success, 15%) !default;
$pagination-hover-border-color: transparent !default;

$pagination-active-bg: $pagination-hover-bg !default;
$pagination-active-border-color: transparent !default;

$pagination-disabled-color: $gray-200 !default;
$pagination-disabled-bg: lighten($success, 15%) !default;
$pagination-disabled-border-color: transparent !default;

// Progress bars

$progress-height: 10px !default;
$progress-font-size: 10px !default;

// List group

$list-group-hover-bg: $gray-200 !default;

$list-group-disabled-bg: $gray-200 !default;

// Close

$close-color: $white !default;
$close-text-shadow: none !default;

// General

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// inputs

$input-disabled-bg: $white !default;
$input-btn-padding-y: 0.95rem !default;
$input-btn-padding-x: 0.95rem !default;

// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: 0 !default;
$input-border-radius-large: 0 !default;
$input-border-radius-small: 0 !default;

// navtabs

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.2rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

// $modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
// $modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.3 !default;
$modal-header-border-color: $gray-200 !default;
// $modal-footer-border-color: $modal-header-border-color !default;
// $modal-header-border-width: $modal-content-border-width !default;
// $modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1.2rem !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;
