@import "variables";

@mixin font-coda() {
  font-family: "Coda", cursive;
  font-weight: 300 !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

@mixin font-fira() {
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
}

body {
  background-color: #f8f8f8 !important;
  color: $brown !important;
}

.navbar-light .navbar-toggler.toggle-orange .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$orange}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.btn {
  @include font-coda();

  padding-top: 0.9em;
  padding-bottom: 0.8em;
  padding-right: 1em;
  padding-left: 1em;

  &.btn-secondary {
    border-color: #ccc !important;
    color: #111;
  }

  &.btn-outline-secondary {
    border-color: #ccc !important;
    color: #111;
  }

  &.btn-link {
    color: $darkBlue;
  }

  &.btn-link:hover {
    color: $lightBlue;
  }
}

.nav-tabs {
  .nav-link {
    @include font-coda;
    cursor: pointer;
    color: $gray-600 !important;
    text-transform: uppercase;
    padding: 1rem 2rem !important;
    font-size: 90%;

    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;

    &:hover {
      border-bottom: 0px;
    }
  }

  .nav-link.active {
    color: $darkBlue !important;

    border-bottom-style: solid !important;
    border-bottom-width: 3px !important;
    border-bottom-color: $darkBlue !important;
  }

  &:hover {
    color: red;
  }
}

.DateInput_input {
  padding: 10px !important;
  font-size: 0.82rem !important;
  line-height: 1.25 !important;
  border-bottom: 0px solid transparent !important;
}

.modal {
  .modal-title {
    color: $darkBlue;
    font-size: 140%;
  }
}

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content.fullscreen .image-gallery-slide {
  background: #000;
}

.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 113px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 84px);
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button,
.image-gallery-left-nav,
.image-gallery-right-nav {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
}
.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  color: #000;
  line-height: 0.7;
  text-shadow: 0 2px 2px #1a1a1a;
  transition: color 0.2s ease-out;
}
.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
  color: #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button:hover::before,
  .image-gallery-play-button:hover::before,
  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before {
    color: #fff;
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
}
.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before {
  font-size: 2.7em;
  padding: 15px 20px;
  text-shadow: 0 1px 1px #1a1a1a;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button::before,
  .image-gallery-play-button::before {
    font-size: 2.4em;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button::before,
  .image-gallery-play-button::before {
    font-size: 2em;
  }
}
.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before {
  color: #fff;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button:hover::before,
  .image-gallery-play-button:hover::before {
    transform: none;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-fullscreen-button::before {
  content: "";
}
.image-gallery-fullscreen-button.active::before {
  content: "";
}
.image-gallery-fullscreen-button.active:hover::before {
  transform: scale(0.9);
}

.image-gallery-play-button {
  left: 0;
}
.image-gallery-play-button::before {
  content: "";
}
.image-gallery-play-button.active::before {
  content: "";
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  color: #fff;
  font-size: 3em;
  padding: 50px 15px;
  top: 45%;
  transform: translateY(-50%);
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}
@media (max-width: 768px) {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-size: 2em;
    padding: 20px 15px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-size: 2em;
    padding: 0 15px;
  }
}

.image-gallery-left-nav {
  left: 0;
}
// .image-gallery-left-nav::before {
//   content: "<";
// }

.image-gallery-right-nav {
  right: 0;
}
// .image-gallery-right-nav::before {
//   content: ">";
// }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.image-gallery-slide {
  // background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 0 #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 108px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail {
  margin-left: 0;
}
.image-gallery-thumbnails-wrapper.left {
  margin-right: 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left {
    margin-right: 3px;
  }
}
.image-gallery-thumbnails-wrapper.right {
  margin-left: 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.right {
    margin-left: 3px;
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform 0.45s ease-out;
  white-space: nowrap;
  margin-top: 10px;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 75px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail img {
  vertical-align: middle;
  width: 100%;
}
.image-gallery-thumbnail.active {
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  color: #1a1a1a;
  font-size: 1em;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
  }
}
.image-gallery-image {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  img {
    cursor: zoom-in;
  }
}

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}
